$tab-width: 641px;
$tab-landscape: (width 568px 640px);
$iphone6-landscape-only: (width 642px 667px);
$tab-pixel2-landscape-up: (width 732px 823px);

.store-locator {
  &__form {
    .store-locator {
      &__locations--current {
        top: 17px;
      }
      &__form-column {
        &--store-type {
          display: none;
        }
      }
    }
  }
  &-section {
    &__list-item {
      &--middle {
        width: 80%;
      }
    }
  }
}

select::-ms-expand {
  display: none;
}

.site-header {
  &__utility-right {
    margin-right: 20px;
    .gnav-util {
      &__icon {
        span {
          position: absolute;
          top: 4px;
          @include breakpoint($landscape-up) {
            position: inherit;
            top: auto;
          }
          &.cart--bracket {
            display: none;
            @include breakpoint($landscape-up) {
              display: inline-block;
            }
          }
          &.visuallyhidden {
            position: absolute;
          }
        }
        &__cart-count {
          width: 23px;
        }
        .icon--bag {
          position: static;
          font-size: 14px;
          padding-left: 0;
          width: 23px;
          height: 23px;
          @include breakpoint($landscape-up) {
            position: relative;
            font-size: 11px;
            padding-left: 4px;
            width: 19px;
            height: 16px;
          }
          &::before {
            font-size: 23px;
          }
        }
      }
    }
  }
  &__utility-left {
    &.mobile-hidden {
      display: block;
      position: absolute;
      width: 43%;
      text-align: center;
      top: 10px;
      left: 44px;
      @include breakpoint($medium-portrait-only) {
        top: 59px;
      }
      @include breakpoint($landscape-up) {
        display: block;
        position: inherit;
        width: auto;
        text-align: center;
        bottom: inherit;
      }
    }
    .site-header {
      &__utility-item {
        border: none;
        &:first-child {
          a {
            display: none;
            @include breakpoint($landscape-up) {
              display: block;
            }
          }
        }
      }
      &__trustmark-wrapper {
        float: none;
        position: inherit;
        top: initial;
        @include breakpoint($medium-portrait-only) {
          left: 33px;
          bottom: -2px;
        }
        @include breakpoint($landscape-up) {
          float: left;
          position: relative;
          top: -16px;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  &__utility {
    .gnav-util {
      &__content--signup {
        z-index: 1;
      }
    }
  }
  &__menu-list {
    @include breakpoint($landscape-up) {
      margin-right: 84px;
    }
    .menu {
      &__item {
        &--lvl-1 {
          font-weight: normal;
        }
      }
    }
  }
  &__menu {
    .site-header {
      &__menu {
        &__mobile-util-links {
          padding-bottom: 100px;
        }
      }
    }
  }
}

.error_messages {
  color: $color-error;
}

.customer-service {
  &__content {
    .cs-contact-form {
      .state {
        display: none;
      }
      &__label {
        width: 100%;
      }
    }
  }
}

.site-footer {
  &__country-chooser-wrapper {
    @include breakpoint($medium-up) {
      bottom: -25px;
    }
    @include breakpoint($landscape-up) {
      bottom: 12px;
    }
  }
  .social-media-links {
    @include breakpoint($landscape-up) {
      margin-top: 70px;
    }
  }
}

.spp_reviews {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-review {
          .pr-rd-footer {
            .pr-helpful-yes {
              .pr-helpful-count {
                &:before {
                  content: ' 是 ';
                }
              }
            }
            .pr-helpful-no {
              .pr-helpful-count {
                &:before {
                  content: ' 否 ';
                }
              }
            }
          }
        }
      }
    }
  }
}

.waitlist-form {
  &__form {
    .waitlist-form__submit {
      vertical-align: top;
    }
    .waitlist-form {
      &__email {
        width: 55%;
        @include breakpoint($medium-up) {
          width: 63%;
        }
      }
    }
  }
  &__header {
    margin-bottom: 0;
  }
}

.write_a_review__container {
  .p-w-r {
    .pr-checkbox {
      input[type='checkbox'] {
        #{$ldirection}: 0;
      }
      span {
        bottom: 4px;
        position: relative;
      }
    }
  }
}

.ff-quiz {
  &__inner {
    .ff-quiz {
      &__slide--1 {
        .ff-quiz {
          &__question {
            top: -70px;
            padding: 0 1%;
            @include breakpoint($tab-width) {
              top: -147px;
              width: 520px;
              padding: 0 5%;
            }
            @include breakpoint($landscape-up) {
              top: -116px;
              width: 770px;
            }
            @include breakpoint($large-only) {
              top: -147px;
              width: 520px;
              padding: 0 5%;
            }
            & > li {
              width: auto;
            }
          }
          &__slider {
            top: -105px;
            @include breakpoint($med-small-only) {
              top: -105px;
            }
            @include breakpoint($tab-landscape) {
              top: -108px;
            }
            @include breakpoint($tab-width) {
              width: 400px;
              top: -193px;
              left: -1.3%;
            }
            @include breakpoint($iphone6-landscape-only) {
              top: -147px;
            }
            @include breakpoint($medium-landscape-only) {
              width: 600px;
              top: -177px;
            }
            @include breakpoint($tab-pixel2-landscape-up) {
              width: 400px;
              top: -193px;
            }
          }
        }
      }
      &__headline--tertiary {
        font-size: 6px;
        @include breakpoint($med-small-only) {
          font-size: 8px;
        }
        @include breakpoint($tab-width) {
          font-size: 10px;
        }
        @include breakpoint($medium-landscape-only) {
          font-size: 11px;
        }
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 15px !important;
    color: $color-dark-gray !important;
  }
}

#footer_sticky_chat {
  @include breakpoint($medium-up) {
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 9998;
  }
}

.sticky__live-chat {
  &__mobile {
    position: fixed;
    bottom: 30%;
    right: 3px;
    text-align: center;
    z-index: 1;
    &--link {
      border: 1px solid $color-gray;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: block;
      background: $color-white;
      img {
        vertical-align: top;
        margin-top: 5px;
      }
    }
  }
}
