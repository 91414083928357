.sign-in-page {
  .new-account {
    &__container {
      .social-login {
        &__divider {
          &::before {
            @include breakpoint($landscape-up) {
              height: 430px;
            }
          }
        }
      }
    }
    .form-item {
      &.show-password {
        width: 100%;
        display: inline-block;
      }
    }
  }
  .return-user {
    &__item {
      &.form-item {
        &.password-container,
        &.submit,
        &.forgot {
          width: 100%;
          float: left;
        }
      }
    }
  }
  .return-user,
  .new-account,
  .social_login_section {
    float: $ldirection;
    .form-item {
      &.password,
      &.first-name,
      &.email-address,
      &.last-name {
        label {
          display: block;
          text-align: left;
        }
      }
    }
  }
  .social-login {
    &__divider {
      &::before {
        #{$ldirection}: auto;
      }
      .inner {
        @include breakpoint($landscape-up) {
          #{$ldirection}: 29em;
        }
      }
    }
  }
  .section-head {
    &__header {
      line-height: 1em;
      font-size: 1.5em;
      text-transform: uppercase;
      font-family: $font--neue-haas-unica-pro;
    }
    &__link {
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 5px;
    }
  }
  .account-links {
    .sign-up-btn,
    .sign-in-btn {
      font-weight: bold;
      font-size: 16px;
      font-family: $font--neue-haas-unica-pro;
      margin-bottom: 10px;
    }
  }
  .return-user,
  .new-account {
    .error_messages {
      max-width: 400px;
    }
  }
}
.order-details-page {
  &__content {
    .order-details {
      &__item-info {
        min-height: auto;
        @include breakpoint($landscape-up) {
          min-height: 315px;
        }
      }
    }
    .order-products {
      .cart-item__qty {
        width: 37%;
      }
      .cart-item__product-sub_line,
      .cart-item__product-subname {
        display: block;
      }
    }
  }
}

.orders-page {
  &__content {
    .orders-list {
      .orders-list {
        &__table {
          &--order-number {
            a {
              &.order-detail-item-link {
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
  }
}

.account-qr_section {
  display: flex;
  justify-content: center;
  margin: 10px;
  @include breakpoint($landscape-up) {
    justify-content: normal;
    margin: 0;
  }
}

.favorites-page {
  &__content {
    .favorites {
      &__item {
        .product-item {
          &__img {
            img {
              @include breakpoint($medium-up) {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

#profile_information {
  .profile-info__item {
    float: $ldirection;
    clear: both;
    width: 60%;
  }
  .birthday_col {
    .form_birthday {
      .selectbox {
        @include breakpoint($medium-up) {
          width: 25%;
        }
        margin-#{$rdirection}: 5px;
      }
    }
  }
}
