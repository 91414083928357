.appt-book {
  .appt-book-section-content {
    padding: 5px 25px 0;
  }
  .location-link {
    .appt-book-link {
      padding-top: 8px;
    }
  }
  .appointment-info-detail h4,
  .location h4,
  .your-lessons h4 {
    letter-spacing: normal !important;
    line-height: 20px !important;
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-family: 'Neue Haas Unica Pro', 'DB Heavent', sans-serif;
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        .registration__email-list {
          height: auto;
          margin-bottom: 0px;
          span {
            font-size: 12px;
          }
        }
      }
      .error {
        a {
          color: $color-error;
        }
      }
      .date-picker {
        .pikaday-container {
          .pika-single {
            .pika-lendar {
              .pika-table {
                th {
                  padding: 17px 0 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

#appt-book-my-appointments-overlay {
  .overlay-content-container {
    .overlay_container {
      .overlay-close {
        top: 20px !important;
      }
    }
  }
}
