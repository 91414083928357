.checkout {
  &__sidebar {
    .viewcart-buttons-panel {
      .continue-checkout {
        width: 100%;
        border-radius: 10px;
        font-size: 12px;
        padding: 0;
        margin: 7px auto 9px auto;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  &.viewcart {
    .checkout-panel {
      &--past-purchases {
        display: block;
        .product-img {
          width: 35%;
          float: left;
        }
        .shades-list,
        .details {
          float: right;
          width: 65%;
        }
        .details {
          margin-bottom: 10px;
        }
      }
    }
  }
  &-table {
    border-bottom: 1px solid $color-lightest-grey;
  }
  &-panel {
    &--shipping-edit-address {
      padding-bottom: 10px;
      @include breakpoint($medium-up) {
        border-bottom: 0;
      }
      .address-form {
        &__city-state-zip {
          .city {
            width: 49%;
            select {
              width: 100%;
            }
          }
        }
        &__phones {
          &.phone1 {
            float: right;
          }
          &.phone2 {
            float: left;
          }
        }
      }
    }
    &__heading-spacing {
      margin: 10px 0 20px 0;
    }
    &.delivery-options {
      border-bottom: 1px solid $color-lightest-grey;
    }
    &:last-child {
      .empty &,
      .shipping & {
        border-bottom: 1px solid $color-lightest-grey;
      }
    }
  }
  .sms-promotions {
    &__item {
      margin: 10px 0;
    }
  }
  &__content {
    .shipping {
      &__delivery-method,
      &__delivery-time-display {
        width: 49%;
        margin-bottom: 10px;
        select {
          width: 100%;
        }
      }
    }
    .invoice-options {
      &__radio {
        margin-bottom: 10px;
      }
      &-container-electronic {
        select {
          width: 100%;
        }
      }
    }
    span {
      &.invoice-method-options {
        display: block;
      }
    }
    .half,
    .halfright {
      width: 49%;
    }
    .halfright {
      float: right;
    }
    .half {
      float: left;
    }
    .new-address {
      .address_controls {
        float: right;
        &--right {
          text-align: right;
          margin: 10px 0 10px 17px;
          select {
            width: 100%;
          }
        }
      }
      .address-form {
        &__addresses {
          margin-bottom: 0;
        }
      }
      .country-id {
        display: none;
      }
    }
    .review-instructions,
    .trans-detail__container--title {
      margin: 0 10px;
    }
    .trans-detail {
      &__container--title {
        font-weight: bold;
      }
    }
    .corporate_name,
    .invoice-address,
    .corporate_tax--id {
      margin-top: 10px;
      input {
        width: 100%;
        margin-bottom: 10px;
        &[type='text'] + label {
          margin-top: calc(-3.5em - 1px);
        }
      }
      span.required_mark {
        display: none;
      }
    }
    .checkout-panel {
      &--order-summary {
        .label {
          text-align: left;
        }
      }
      ul.error_messages {
        margin-bottom: 10px;
        width: 100%;
        margin: 0;
        text-align: center;
      }
    }
    .adpl {
      .state {
        &.select {
          label {
            display: block;
          }
        }
      }
    }
    .gift_form_container {
      .form-item {
        margin-bottom: 10px;
      }
      textarea {
        &.error {
          border: 1px solid $color-error;
        }
      }
    }
  }
  .payment-container {
    .bankname {
      width: 40%;
      select {
        margin: 5% 0 0 10%;
        clear: both;
      }
      p {
        margin-left: 10%;
      }
    }
    .bank_card {
      float: left;
      margin-left: 4%;
      width: 10%;
    }
  }
  .pickup {
    &-options {
      img {
        width: 50px;
        height: 50px;
      }
      label {
        display: flex;
        &::before {
          margin-right: 8px;
          margin-top: 2px;
        }
      }
    }
    &-label {
      padding: 10px 0px 5px 10px;
      float: left;
    }
    &-store {
      padding: 5px 10px;
      float: left;
    }
  }
  .payment-panel {
    .header-section {
      clear: both;
      display: inline-block;
      width: 100%;
      margin-left: 10px;
    }
    .nccc-media {
      float: right;
      padding: 10px;
      @include breakpoint($landscape-up) {
        padding: 5px 24px 0 0;
      }
      img {
        height: 25px;
        width: 35px;
      }
    }
    .non-installment-options,
    .linepay-options,
    .installment-options {
      border: 1px solid $color-lightest-grey;
      margin-bottom: 10px;
      @include breakpoint($landscape-up) {
        margin-right: 20px;
      }
      &__header {
        padding: 10px;
      }
      &__radios {
        padding-left: 10px;
        margin: 0 0 0.8em 0;
      }
    }
    .linepay-options {
      &__radios {
        img {
          width: 74px;
          height: 24px;
        }
      }
    }
    &.checkout-panel {
      border-bottom-width: 1px;
    }
  }
  &.review,
  &.shipping {
    .continue-button-wrapper {
      margin: 20px 0;
    }
    h2 {
      &.checkout-panel__heading {
        input {
          float: right;
        }
        font-weight: bold;
      }
    }
    li[id='offer_bogo..'] {
      display: none;
    }
  }
  &-confirmation-page {
    .checkout {
      &__sidebar {
        .checkout-buttons {
          @include breakpoint($small-down) {
            width: 100%;
          }
        }
      }
    }
  }
  .offer_code_form_container {
    .offer-code-content {
      &__input {
        width: 60%;
        min-width: 60%;
        @include breakpoint($landscape-up) {
          width: 100%;
          min-width: 100%;
          margin-bottom: 10px;
        }
      }
      &__btn {
        width: 30%;
        min-width: 30%;
        @include breakpoint($landscape-up) {
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }
  .checkout__heading {
    margin-bottom: 0;
  }
  .checkout-panel {
    .continue-button-wrapper {
      input {
        float: right;
      }
    }
  }
}

.order-details-page {
  .order-details {
    &__container {
      .order-details {
        .account-utilities {
          &__customer-service {
            padding: 0;
            border: none;
          }
        }
      }
    }
  }
}

.checkout-progress-bar {
  &__list {
    &-item {
      padding: 0 13px;
      @include breakpoint($medium-up) {
        padding: 0 20px;
      }
    }
  }
}

#payPage {
  input[type='reset'] {
    display: inline-block;
    background: $color-dark-gray;
    color: $white;
    border: 1px solid $color-dark-gray;
    border-radius: 4px;
    padding: 13px 15px 14px 15px;
    line-height: 1;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    transition: background 0.3s, color 0.3s, border-color 0.3s;
    text-transform: uppercase;
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      background: $color-green;
      color: $white;
      border-color: $color-green;
    }
  }
}

.active-panel {
  &-payment {
    .checkout {
      &__content,
      &__sidebar {
        @include breakpoint($tablet-only) {
          width: 100%;
        }
        iframe {
          @include breakpoint($tablet-only) {
            width: 100%;
          }
        }
      }
    }
  }
  &-review,
  &-shipping,
  &-payment,
  &-samples {
    .checkout {
      overflow: visible;
      @include breakpoint($medium-up) {
        overflow: hidden;
      }
    }
    .site-header {
      &.sticky {
        .site-header {
          &__utility-left {
            &.pc-hidden {
              @include breakpoint($medium-up) {
                display: block;
              }
            }
          }
        }
      }
      &__utility-left {
        &--pc {
          display: none;
        }
      }
    }
  }
}
#pickup-address {
  .btn {
    a {
      background: $color-black;
      color: $white;
      padding: 13px 15px 13px 15px;
      line-height: 4;
    }
  }
}
#continue-checkout {
  float: right;
}
.return_link {
  float: left;
}
.mobile-checkout-back {
  .back-button__text {
    display: inline;
    font-size: 17px;
    font-weight: bold;
    margin: 0 10px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}
