/* Disconnect button in profile preference page */
#facebook_disconnect input.form-submit {
  margin: 0;
  float: none;
}
/* Checkout Page Styles */
.checkout__index-content {
  .social-login-section {
    .social-login__terms p {
      margin: 0;
    }
  }
}

.checkout-panel__content {
  .social-login-section {
    .social-login {
      &__container {
        width: auto;
      }
    }
  }
}

.checkout-panel {
  .social-login-section {
    margin-top: 0;
  }
  &--new-account {
    .new-account-content {
      .sign-in-btn {
        margin-bottom: 0;
      }
    }
  }
  &--return-user {
    .guest-btn {
      margin-bottom: 0;
    }
    .social-login__terms {
      padding-top: 20px;
    }
  }
  &--sign-in-main {
    .social-login {
      &__divider {
        margin: 0 auto 30px auto;
      }
    }
  }
}
/* account Page Styles */
.return-user__social,
.new-account__social {
  .social-login {
    &__container {
      display: block;
    }
    &__divider {
      @include facebook_button_divider;
      width: 100%;
      color: $color-light-gray;
      margin: 0 auto 10px auto;
      @include breakpoint($landscape-up) {
        display: none;
      }
      &:before {
        background: $color-light-gray;
        height: 1px;
      }
    }
  }
}
/* Space Confirm styles */
.new_user__panel {
  padding: 1em;
  .social-login {
    &__container {
      display: block;
    }
  }
}
/* For displaying the FB Button */
.social-login-section {
  .social-login {
    &__container {
      position: relative;
    }
  }
}

.checkout__sidebar {
  border: 0;
}

.social-login {
  margin: 0;
  .facebook-button {
    display: none;
  }
  &__container {
    display: block;
  }
  &__email-opt-in {
    text-align: #{$ldirection};
    padding-bottom: 5px;
    padding-top: 20px;
  }
  &__terms p {
    text-align: justify;
  }
  &__divider {
    margin: 0;
  }
}
/* Order Confirmation Page */
#confirmation-page {
  .social-login {
    margin: 0;
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      text-align: #{$ldirection};
    }
    &__info {
      display: block;
      text-align: #{$ldirection};
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
    &__container {
      margin: 0;
    }
    &__terms {
      display: none;
    }
  }
}
/* GNAV Style */
.site-header__utility {
  .gnav-util__content--account {
    .login {
      .social-login {
        &__container {
          position: unset;
        }
        &__email-opt-in {
          text-align: #{$ldirection};
          padding-top: 20px;
        }
        .facebook-button.facebook-button-disabled {
          display: none;
        }
        &__terms {
          text-align: justify;
          label {
            display: none;
          }
        }
      }
    }
  }
}
/* GNAV Registration */
.gnav-util--account {
  .registration {
    .social-login {
      &__container {
        position: unset;
      }
      &__email-opt-in {
        text-align: #{$ldirection};
      }
      &__terms {
        text-align: justify;
      }
    }
  }
}
